.a-part-img-div {
  position: relative;
  cursor: pointer;
  /* border: 2px solid #98a2b3; */
  border-radius: 4px;
  height: 154px;
  width: 100%;
  background-color: #f9fafb;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

/* .a-part-img-div:hover {
  border: 5px solid #32d583;
} */

.a-part-img-div-activate {
  border: 5px solid #d53232;
}

.a-part-img-active {
  position: absolute;
  width: 100%;
  height: 154px;
  /* background-color: rgb(53 249 6 / 33%); */
  background-color: #494f6daf;
  border-radius: 4px;
  padding: 5px;
}

.a-part-img {
  border-radius: 4px;
  /* width: 100%;
  height: 144px; */
  padding: 5px;
}

.img-height-100 {
  height: 100%;
  max-height: 144px;
}

.img-width-100 {
  width: 100%;
  max-height: 144px;
}

.note-label {
  position: absolute;
  top: 5px;
  right: 5px;
  margin: 0;
  z-index: 2;
}

.printed-label {
  position: absolute;
  top: 5px;
  right: 5px;
  margin: 0;
  z-index: 2;
}

.part-label {
  position: absolute;
  top: 5px;
  left: 5px;
  margin: 0;
  z-index: 2;
}

.p-delete-button {
  position: absolute;
  bottom: 5px;
  right: 5px;
  margin: 0;
  z-index: 2;
}

.e-printed-label {
  position: absolute;
  bottom: 5px;
  right: 5px;
  margin: 0;
  z-index: 1;
}

.part-img-tag {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  max-height: 100%;
  transform: translate(-50%, -50%);
  padding: 1rem;
}

.part-img-active-div {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #494f6daf;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
