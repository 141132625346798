.p-head-text {
  display: inline;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: black;
  font-family: 'Poppins', sans-serif;
  margin: 0;
}

.s-key-text {
  display: inline;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #667085 !important;
  font-family: 'Poppins', sans-serif;
  margin: 0;
}

.profile-row-card {
  background-color: #ffffff;
  border: 1px solid #f9fafb;
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
}
