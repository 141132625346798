:root {
  --main-color: #046fa0;
  --theme-dark-color: #000000;
  --theme-light-blue: #62c5f3;
}

body {
  background-color: aliceblue !important;
  font-family: 'Poppins', sans-serif;
}

.btn-style-set {
  height: 40px;
  color: white;
  padding: 5px 10px;
  border-radius: 0px;
  text-align: center;
  cursor: pointer;
  font-size: 20px;
}

.print-btn {
  background-color: #a00404;
  border: 1px solid #a00404;
}

.btn-link-style {
  background-color: transparent;
  border: transparent;
  color: var(--theme-dark-color);
  font-size: 20px;
}

.modal-head {
  background: var(--theme-dark-color);
  border: var(--theme-dark-color);
  color: white;

  font-size: 20px;
}

.modal-head button {
  background: transparent;
  border: transparent;
  color: white;
  font-size: 20px;
}

.button-add-style {
  text-align: center;
  background: var(--main-color);
  border: 1px solid var(--main-color);
  border-radius: 7px;
  padding: 10px 10px;
  margin: 0 5px;
  cursor: pointer;
  color: white;
  float: right;
  font-size: 17px;
}

.button-add-style:hover {
  background: #ffffff;
  border: 1px solid var(--main-color);
  color: var(--main-color);
}

.table_outer_div {
  box-shadow: 1px 1px 6px var(--theme-dark-color);
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 4px;
  margin: 5px 0;
}

.table_outer_div .rdt_TableHeader {
  color: white;
  background-color: var(--theme-dark-color);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-size: 17px;
}

.table_outer_div .rdt_Pagination {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.table_outer_div .header-txt {
  padding: 10px 10px 10px 10px;
  color: white;
  background-color: var(--theme-dark-color);
  letter-spacing: 0.4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 17px;
}

.table td {
  padding: 0.75rem 10px;
}

.table_outer_div table {
  padding: 10px;
}

.ant-layout-sider-children {
  background-color: #000000;
}

:where(.css-dev-only-do-not-override-1m62vyb).ant-menu-dark .ant-menu-item-selected,
:where(.css-dev-only-do-not-override-1m62vyb).ant-menu-dark > .ant-menu .ant-menu-item-selected {
  background-color: #53389e;
}

.ant-menu {
  background-color: transparent;
  font-family: 'Poppins', sans-serif;
}

.ant-menu-item {
  border-radius: 0px !important;
}

.user-dropdown {
  position: absolute;
  bottom: 10px;
}

.user-button {
  width: 100%;
  height: 45px;
  background-color: transparent;
  border: none;
  text-align: left;
}

.p-avatar {
  background-color: orange;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
}

/* .ant-avatar-string {
  line-height: 31px !important;
} */

.user-button,
.user-button:hover {
  color: rgba(134, 134, 134, 0.767) !important;
  font-family: 'Poppins', sans-serif;
}

.center-v-div {
  display: flex;
  align-items: center;
}

.white-card {
  background-color: #ffffff;
  border: 1px solid #f9fafb;
  margin-top: 5px;
  padding: 10px;
  border-radius: 4px;
}

.sub-page-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  display: inline;
}

.ant-modal-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  font-family: 'Poppins', sans-serif;
}

.ant-menu-item-selected {
  background-color: #53389e !important;
}

.e-pdf-div {
  width: 100%;
  height: 80vh;
}

.rti--container .rti--tag button {
  font-size: 12px;
}

.switch-btn {
  background-color: #cccccc;
  width: 50px;
  height: 24px;
  border-radius: 12px;
  cursor: pointer;
}

.remarks-text {
  color: #cc6825;
  cursor: pointer;
}

.top-side .s-part-title {
  display: inline;
  margin-right: 10px;
}

.remarks-text-div {
  display: inline;
}
