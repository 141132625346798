.m-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-top: 5px;
  color: gray;
  font-family: 'Poppins', sans-serif;
}

.tag-div-height {
  margin-top: 20vh !important;
  text-align: center;
}
