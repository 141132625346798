.back-button {
  background-color: transparent !important;
  color: #667085 !important;
  box-shadow: none;
}

.job-title {
  display: inline;
  font-weight: 600;
  font-size: 30px;
  line-height: 54px;
  color: black;
  font-family: 'Poppins', sans-serif;
  margin: 0;
}

.area-button {
  background: white;
  font-weight: 700;
  width: 100%;
  height: 140px !important;
  border: 2px solid #98a2b3;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  font-family: 'Poppins', sans-serif;
  color: gray;

  position: relative;
}

.area-button:hover {
  border: 2px solid #98a2b3 !important;
  color: gray !important;
}

.area-button span {
  white-space: normal;
}

.active-button,
.active-button:hover {
  color: white !important;
  background: #53389e !important;
  border-color: #53389e !important;
}

.a-label {
  position: absolute;
  top: 5px;
  right: 0px;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  background-color: #1d2939;
  padding: 3px;
  /* height: 20px; */
  /* width: 20px; */
  text-align: center;
}

.part-img-div {
  cursor: pointer;
  border: 2px solid #98a2b3;
  border-radius: 4px;
  height: 154px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.part-img-div:hover {
  border: 5px solid #32d583;
}

.part-img-active {
  border: 5px solid #32d583;
}

.part-img {
  border-radius: 4px;
  width: 100%;
  height: 144px;
  padding: 5px;
}

.right-side-bar {
  background-color: black;
  height: 98vh;
  /* overflow-y: scroll; */
}

.close-button {
  background-color: transparent !important;
  color: #ffffff !important;
  box-shadow: none;
}

.s-part-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
}

.part-img-item-div {
  border-radius: 8px;
  height: 214px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.part-item-img {
  border-radius: 8px;
  width: 100% !important;
  max-height: 224px !important;
  padding: 5px;
}

.s1-part-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  display: inline;
}

.s1-part-sub-text {
  font-size: 14px;
  line-height: 21px;
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
}

.link-label {
  color: #000000;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  background-color: #ffffff;
  padding: 3px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  width: 40px;
  margin-bottom: 5px;
}

.ant-select-selector {
  border-radius: 4px !important;
}

.middle-div {
  height: 98vh;
  overflow-y: scroll;
}

.a-delete-button {
  position: absolute;
  bottom: 5px;
  right: 5px;
  margin: 0;
  z-index: 1;
}

.a-edit-button {
  position: absolute;
  bottom: 5px;
  left: 5px;
  margin: 0;
  z-index: 1;
}

.warning-message {
  font-size: 16px;
  line-height: 21px;
  font-family: 'Poppins', sans-serif;
}

.job-area-div,
.job-part-div {
  overflow-y: auto;
}

.icon-next-btn {
  text-align: end;
}

.icon-next-btn button {
  background-color: #1677ff;
  border: none;
  color: white;
}

.icon-next-btn button:hover {
  color: white !important;
}

.right-side-bar-first {
  /* max-height: calc(100vh - 70px); */
  overflow-y: auto;
}

.print-part-list-div {
  max-height: 70vh;
  overflow-y: auto;
}

.job-input-outer-div {
  width: 100%;
}

.delete-job-btn {
  color: red !important;
}

.edit-job-btn {
  margin-left: 10px;
}

.update-job-btn {
  color: green !important;
}

.part-img-card-div {
  width: 154px !important;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
}

.grid > div {
  background: #f9fafb;
  border-radius: 4px;
  cursor: pointer;
  padding: 1rem;
  position: relative;
}

.grid > div::before {
  content: '';
  padding-top: 100%;
  display: block;
}

/* .grid > div > img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  transform: translate(-50%, -50%);
} */
