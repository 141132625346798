.logo-img {
  width: 100%;
  max-width: 150px;
}

.l-head-text {
  font-weight: 500;
  font-size: 24px;
  color: black;
  line-height: 36px;
  text-align: center;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

.l-sub-text {
  font-weight: 500;
  font-size: 12px;
  color: black;
  line-height: 18px;
  text-align: center;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

.input-label {
  font-weight: 500;
  font-size: 14px;
  color: black;
  line-height: 21px;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

.l-input-box,
.l-input-box::selection {
  height: 44px;
}

.login-background-img {
  width: 100%;
}

.login-img-background-div {
  padding: 0;
  /* min-height: calc(100vh - 60px); */
  min-height: calc(100vh);
  display: flex;
  flex-direction: column;
  background-color: #e6e6e6;

  background-image: url('./login-background-screen.jpg');
  background-position: bottom;
}

.container-width-set-none {
  max-width: inherit !important;
}

.button-for-webpage:link,
.button-for-webpage:visited {
  background-color: var(--dark-blue);
  color: rgb(255, 255, 255);
  border: 2px solid var(--dark-blue);
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

.button-for-webpage:hover,
.button-for-webpage:active {
  background-color: var(--light-blue);
  color: white;
}

.l-left-join {
  margin-top: 5vh;
  background: black;
}

.margin-top-set-20vh {
  margin-top: 30vh;
}

.margin-top-set-10vh {
  margin-top: 10vh !important;
}

.login {
  background: black;
  height: 100vh !important;
}

.logo-login {
  width: 150px;
  border-radius: 10px;
}

.title-tag-style {
  font-weight: 600;
  font-size: 22px;
  color: white;
  line-height: 18px;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

.normal-p-tag-style {
  font-weight: 500;
  font-size: 16px;
  color: white;
  line-height: 18px;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

.login-img-div {
  text-align: center;
}

.login-body {
  position: relative;
  background-color: black;
  min-height: 100vh;
}

.center-login-form {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.login-form-div {
  border: 4px solid #32d583;
}

.logo-img {
  width: 100%;
  max-width: 350px;
}

.l-head-text {
  font-weight: 500;
  font-size: 24px;
  color: white;
  line-height: 36px;
  text-align: center;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

.l-sub-text {
  font-weight: 500;
  font-size: 12px;
  color: white;
  line-height: 18px;
  text-align: center;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

.input-label {
  font-weight: 500;
  font-size: 14px;
  color: white;
  line-height: 21px;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

.l-input-box,
.l-input-box::selection {
  border: 2px solid #9603bb !important;
  background-color: black !important;
  color: white !important;
  height: 44px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
}
