.page-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  display: inline;
}

.job-add-button {
  text-align: center;
  background: #1677ff;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

.job-add-button:hover {
  /* background: #ffffff;
  border: 1px solid var(--main-color); */
  color: white !important;
}

.part-delete-button {
  text-align: center;
  background: #ff1616;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

.part-delete-button:hover {
  /* background: #ffffff;
  border: 1px solid var(--main-color); */
  color: white !important;
}

.plan-view-button {
  text-align: center;
  background: #31d890;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

.plan-view-button:hover {
  /* background: #ffffff;
  border: 1px solid var(--main-color); */
  color: white !important;
}

.job-row-card {
  background-color: #ffffff;
  border: 1px solid #f9fafb;
  margin-top: 5px;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.load-job-row-card {
  background-color: #ffffff;
  border: 1px solid #f9fafb;
  margin-top: 5px;
  padding: 9px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.job-row-card:hover {
  background-color: #8a8888;
}

.job-row-card-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: black;
  font-family: 'Poppins', sans-serif;
  padding: 10px;
}

.j-label {
  background-color: #f9fafb;
  padding: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #667085;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  text-transform: uppercase;
}

.j-button {
  background-color: transparent;
  color: #667085;
}

.jog-list-div {
  overflow-y: auto;
}
