.a-input-label {
  font-weight: 500;
  font-size: 14px;
  color: Black;
  line-height: 21px;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

.a-input-box {
  height: 53px;
}

.a-div {
  background-color: #f9fafb;
  border-radius: 4px;
  padding: 16px;
}

.add-area-parts {
  max-height: 50vh;
  overflow-y: auto;
}
