.m-div {
  text-align: center;
}

.m-image {
  width: 100%;
  max-width: 120px;
}

.m-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-top: 5px;
  color: gray;
  font-family: 'Poppins', sans-serif;
}
