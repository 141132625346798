.l-div {
  text-align: center;
  min-height: 200px;
  margin: auto;
}

.l-spin {
  margin-top: 80px;
}

.l-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-top: 15px;
  color: gray;
  font-family: 'Poppins', sans-serif;
}

.job-loading-card {
  background-color: #ffffff;
  border: 1px solid #f9fafb;
  margin-top: 5px;
  padding: 10px;
  border-radius: 4px;
}
