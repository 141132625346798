.part-section {
  cursor: pointer;
}

.part-section:hover {
  stroke: #1b8ae5;
  stroke-width: 5px;
}

.active-part {
  fill: #53389ed1;
}

.active-linked-part {
  fill: rgb(8, 109, 185);
}

.already-linked-part {
  fill: #0220384a;
}

.plan-item-button {
  text-align: center;
  background: #31d890;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

.plan-item-button:hover {
  /* background: #ffffff;
  border: 1px solid var(--main-color); */
  color: white !important;
}

.active-plan-item-button {
  background: #d8315e !important;
}

.link-enable-button {
  text-align: center;
  background: #2c8dee;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

.link-enable-button:hover {
  /* background: #ffffff;
  border: 1px solid var(--main-color); */
  color: white !important;
}

.link-submit-button {
  text-align: center;
  background: #b24607;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

.link-submit-button:hover {
  /* background: #ffffff;
  border: 1px solid var(--main-color); */
  color: white !important;
}
